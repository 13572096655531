import React, { useState, useEffect } from "react"
import { auth, GoogleAuthProvider, firestore } from "../firebase"

import { telegram } from "../utils/telegram"

const AuthContext = React.createContext()

export function useAuth() {
  return React.useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [images, setImages] = useState(null)

  async function configUser(user) {
    // const configRef = firestore.collection("config").doc("default")
    // const configDoc = await configRef.get()

    // let free_balance = 8

    // if (configDoc.exists) {
    //   const configData = configDoc.data()
    //   free_balance =
    //     configData.freeBalance !== undefined
    //       ? configData.freeBalance
    //       : free_balance
    // }

    const userRef = firestore.collection("users").doc(user.uid)

    const createdAt = new Date()
    const referrer = localStorage.getItem("referrer")
    const searchKeyword = localStorage.getItem("search_keyword")
    const firstLandingPage = localStorage.getItem("first_landing_page") || "/"

    //const group = Math.random() < 0.5 ? "A" : "C"
    const group = "A"

    const userData = {
      email: user.email,
      //balance: group === "C" ? 20 : 0,
      balance:
        firstLandingPage === "/tools/high-resolution-image-converter" ? 30 : 0,
      createdAt: createdAt,
      referrer: referrer,
      firstLandingPage: firstLandingPage,
      plan: "free",
      group: group,
    }

    if (searchKeyword) {
      userData.search_keyword = searchKeyword
    }

    await userRef.set(userData)
    return userData
  }

  async function signupWithEmail(email, password) {
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      )
      const user = userCredential.user
      await configUser(user)
      return "success"
    } catch (error) {
      telegram(
        "frontend, AuthContext, signupWithEmail, error: " + error.message
      )
      throw new Error("Failed to create an account")
    }
  }

  async function signupWithGoogle() {
    try {
      const provider = new GoogleAuthProvider()
      const userCredential = await auth.signInWithPopup(provider)
      const user = userCredential.user
      const isNewUser = userCredential.additionalUserInfo?.isNewUser

      if (isNewUser) {
        const userData = await configUser(user)
        return { state: "new user", group: userData.group }
      } else {
        const userDoc = await firestore.collection("users").doc(user.uid).get()
        const userData = userDoc.data()
        return { state: "existing user", group: userData.group }
      }
    } catch (error) {
      // Handle error
      return { state: "error", error: error.message }
    }
  }

  function login(email, password) {
    try {
      telegram("User login: " + email)
      return auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
      telegram("frontend, AuthContext, login, error: " + error.message)
    }
  }

  const logout = async () => {
    await auth.signOut()
    setCurrentUser(null)
    setUser(null)
    setImages(null)
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  useEffect(() => {
    let unsubscribeAuth = null
    let unsubscribeUser = null
    let unsubscribeImages = null

    const setupListeners = async () => {
      // Auth listener
      unsubscribeAuth = auth.onAuthStateChanged((user) => {
        setCurrentUser(user)
        setLoading(false)
      })

      if (currentUser) {
        // User data listener
        const usersRef = firestore.collection("users").doc(currentUser.uid)
        unsubscribeUser = usersRef.onSnapshot(async (snapshot) => {
          if (snapshot.exists) {
            const userData = snapshot.data()
            const userId = snapshot.id
            try {
              const paymentsRef = usersRef.collection("payments")
              const collectionsPayments = await paymentsRef.get()
              const collectionsData = collectionsPayments.docs.map((doc) =>
                doc.data()
              )
              const sumOfCredits = collectionsData
                .filter((item) => item.status === "succeeded")
                .flatMap((item) => item.items)
                .map((item) => parseInt(item.price.metadata.credits))
                .filter((credits) => !isNaN(credits))
                .reduce((accumulator, current) => accumulator + current, 0)

              setUser({ id: userId, ...userData, credits_bought: sumOfCredits })
            } catch (error) {
              console.error("Error fetching payments:", error)
            }
          }
        })

        // Images listener
        const imagesRef = firestore
          .collection("users")
          .doc(currentUser.uid)
          .collection("images")
          .orderBy("createdAt", "desc")
          .limit(2000)

        unsubscribeImages = imagesRef.onSnapshot(async (querySnapshot) => {
          const imagesData = []
          querySnapshot.forEach((doc) => {
            const imageData = doc.data()
            const imageId = doc.id
            imagesData.push({ id: imageId, ...imageData })
          })
          console.log("Loaded images from Firestore:", imagesData.length)
          setImages(imagesData)
        })
      }
    }

    setupListeners()

    return () => {
      if (unsubscribeAuth) unsubscribeAuth()
      if (unsubscribeUser) unsubscribeUser()
      if (unsubscribeImages) unsubscribeImages()
    }
  }, [currentUser])

  const value = {
    currentUser,
    signupWithGoogle,
    signupWithEmail,
    login,
    logout,
    resetPassword,
    user,
    images,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
