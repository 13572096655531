import React from "react"

function ContactButton() {
  return (
    <>
      <a
        href="https://twitter.com/philz1337x"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-0 z-1000 right-0 text-black bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-100 font-bold rounded-tl-2xl text-xs px-2 py-2 text-center inline-flex items-center"
        aria-label="Contact on Twitter"
      >
        by @philz1337x
      </a>
    </>
  )
}

export default ContactButton
