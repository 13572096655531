import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

const app = firebase.initializeApp({
  apiKey: "AIzaSyAgS23Ic5TkGWWVE6uLTh0Cm5kGVtfbbPo",
  authDomain: "clarityai.co",
  projectId: "upscaler-89296",
  appId: "1:691640351745:web:e6afff36dff89e6b085400",
})

export const auth = app.auth()
export const firestore = app.firestore()
export const firebaseInstance = firebase
export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider

export default app

export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
